import React from 'react';

import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Card } from 'react-bootstrap';
import '../scss/sampleCashbackMerchants.scss';


const SampleCashbackMerchants = () => {
  const sampleCashbackMerchantsChart = useStaticQuery(graphql`
    query SampleCashbackMerchantsQuery {
      allContentfulOnboardFlow {
        nodes {
          dashboardcashbackCategories {
            finePrint
            header
            subheader
            categories {
              name
              merchants {
                name
                url
              }
            }
          }
        }
      }
    }
  `);

  // React.useEffect(() => {

  // }, []);

  if (!sampleCashbackMerchantsChart) {
    return <h1>No Data Available</h1>;
  }

  const data = sampleCashbackMerchantsChart.allContentfulOnboardFlow.nodes[0].dashboardcashbackCategories;

  if (!data) {
    return <></>
  }

  return (
    <div className="mcc-section-content mx-5 my-5">
      <h1 dangerouslySetInnerHTML={{ __html: data.header }} />
      <p dangerouslySetInnerHTML={{ __html: data.subheader }} />
      <div className="mccs-section mt-5">
        {data.categories.map((c, idx) => (
          <Card className={`total-interest-card panel`} key={`mcc-${idx}`}>
            <Card.Body>
              <h3>{c.name}</h3>
              {/* <p>Merchant Category Code:<br />{c.mcc}</p> */}
              {c.merchants.map((m, idx2) => (
                <div key={`merchant-${idx2}`}>
                  <a href={m.url} target="_blank" className="purple-text thin">{m.name}</a>
                </div>
              ))}
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="subtext mt-5">
        {data.finePrint.map((f, idx) => (
          <p key={idx}>{f}</p>
        ))}
      </div>
    </div>
  );

};

export default SampleCashbackMerchants;
